import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-activate-account',
  templateUrl: 'account-activation.component.html'
})
export class AccountActivationComponent implements OnInit {

  username: string;
  code: string;
  loading: boolean;
  show: boolean;

  constructor(private auth: AuthService,
    private route: ActivatedRoute,
    protected router: Router, protected toastr: ToastrService) { }

  ngOnInit(): void {
    this.auth.logout();

  }

  resset() {
    this.loading = true;
    const data = new FormData();
    data.append('telephone', this.username);
    this.auth.activateAccount(data).subscribe(res => {
      this.loading = false;
      if (res.body.status === 'success') {
        this.loading = false;
        this.show = true;
      } else {
        this.toastr.error(res.body.message, 'Activation');
      }
    }, err => {
      this.loading = false;
      this.toastr.error("Une erreur est survénue, veuillez réessayer", 'Activation');
    });
  }

  confirm() {
    this.loading = true;
    const data = new FormData();
    data.append('code', this.code);
    data.append('telephone', this.username);
    this.auth.registerItechpayMarchandConfirm(data).subscribe(res => {
      //console.log(res);
      if (res.body.status !== 'success') {
        this.toastr.error(res.body.message, 'Activation');
        this.loading = false;
      } else {
        this.toastr.success('Votre compte a été réactivé avec succès, veuillez vous connecter!', 'Activation');
        this.router.navigate(['/login']);
      }
    }, err => {
      this.toastr.error("Une erreur est survenue, veuillez réessayer", 'Activation');
    });
  }

  ressendCode() {
    this.loading = true;
    const data = new FormData();
    data.append('telephone', this.username);
    this.auth.resendCode(data).subscribe(res => {
      if (res.body.status !== 'success') {
        this.toastr.error(res.body.data.message, 'Inscription');
        this.loading = false;
      } else {
        this.toastr.success('Le code de confirmation a été renvoyé', 'Inscription');
        this.loading = false;
      }
    }, err => {
      this.loading = false;
      this.toastr.error("Une erreur est survénue, veuillez réessayer", 'Activation');
    });
  }

}
