<app-navigation [opac]="true"></app-navigation>
<header>
	<div class="header-item">
		<div class="container">
			<div class="row title-block">
				<div class="col-xs-12 col-md-6">
					<h4 class="title"><b>Sélectionnez un moyen de deboursement</b></h4>
				</div>
				<div class="col-xs-12 col-md-6">
					<div class="stepwizard">
						<div class="stepwizard-row">
							<div class="stepwizard-step">
								<button type="button" class="btn btn-primary btn-circle">1</button>
							</div>
							<div class="stepwizard-step">
								<button type="button" class="btn btn-default btn-circle">2</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
<section class="bg-grey dashboard">
	<ng-container *ngIf="lds">
		<div class="loading">
			<div class="lds-dual-ring"></div>
		</div>
	</ng-container>
	<div class="row justify-content-center" style="padding-top: 24px;" *ngIf="!lds">
		<div class="col-xs-12 col-md-3 mb-3" *ngFor="let operator of operators">
			<div class="card-pay">
				<div class="box">
					<div class="img">
						<img [src]="getOperatorLogo(operator)">
					</div>
					<h2>{{ operator?.name }}</h2>
					<a class="btn2 btn2-default" (click)="goToPaymentForm(operator)">Payer par
						{{ operator?.name }}</a>
				</div>
			</div>
		</div>
	</div>
</section>
<app-footer></app-footer>