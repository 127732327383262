<app-navigation></app-navigation>
<header class="home">
  <div class="header__bg"></div>
  <div class="container">
    <div class="row header-row">
      <div class="col-xs-12 col-md-8">
        <div class="header-infos land-text">
          <h3 class="header-title">Votre plateforme d'aggrégation de moyens de
            paiements</h3>
          <a *ngIf="isAutenticated" class="btn btn-primary btn-lg" routerLink="/dashboard" style="color: white;">Mes
            paiements</a>
            <a *ngIf="isAutenticated" class="btn btn-primary btn-lg" routerLink="/deboursements/liste" style="color: white;">Mes
              remboursements</a>
          <a *ngIf="!isAutenticated" class="btn btn-primary btn-lg" routerLink="/inscription"
            style="color: white;">Créer un compte</a>
          <a *ngIf="!isAutenticated" class="btn btn-primary btn-lg" id="howTo" style="background: transparent;">Comment
            ça marche?</a>
          <a *ngIf="isAutenticated" class="btn btn-primary btn-lg" routerLink="/paiements-client/external-link"
            style="background: transparent; color: #333;">Liens
            de paiements</a>
        </div>
      </div>
      <div class="col-xs-12 col-md-4 d-none d-md-block">
        <img class="header-image" src="assets/img/computer_banner.png" alt="mobile paiement">
      </div>
    </div>
  </div>
</header>

<!-- Page Content -->
<section class="py-5" id="howtosection">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-xs-12">
        <div class="bloc-1">
          <h2 class="home-title color-primary"><b>Comment ça marche ?</b></h2>
          <p class="lead"></p>
          <table class="table table-striped table-responsive table-home">
            <col style="width:30%">
            <col style="width:70%">
            <tbody>
              <tr>
                <td class="text-center table-title"><b routerLink="/type-entreprise">Solution pour les marchands</b>
                </td>
                <td>
                  <ul class="tlist">
                    <li>
                      Créez facilement votre compte en ligne
                    </li>
                    <li>
                      Consultez le statut de vos paiements et recevez les immédiatement sur votre compte
                    </li>
                    <li>
                      Et remboursez vos clients en cas d'erreur
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="text-center"><b routerLink="/type-entreprise">Liens de paiement sécurisés</b>
                </td>
                <td>
                  <ul class="tlist">
                    <li>
                      Générez un lien de paiement avec le montant de la facture
                    </li>
                    <li>
                      Envoyez le lien de paiement à votre client afin qu'il puisse vous regler avec son moyen de paiement préféré.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bloc-1">
          <p class="lead"></p>
        </div>
      </div>
      <div class="col-md-4 col-xs-12">
        <div class="side-pan">
          <div class="card" style="width: 100%;">
            <img src="assets/img/RESTful-API.png" class="card-img-top" alt="ressources download">
            <div class="card-body">
              <h5 class="card-title"><b>API</b></h5>
              <p class="card-text">Utilisez les API de la plateforme pour facilement intégrer une multitude de moyens de paiements à votre business.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>