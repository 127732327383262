import { Component, OnInit } from '@angular/core';
import { DeboursementService } from './deboursement.service';

@Component({
  selector: 'app-recu',
  templateUrl: './recu.component.html',
  styleUrls: ['./recu.component.css']
})
export class RecuComponent implements OnInit {

  donnees: any;
  
  constructor(private deboursementService: DeboursementService) { }

  ngOnInit(): void {
    this.donnees = this.deboursementService.getDonneeDeboursement();
  }

}
