<section class="full-height">
    <nav class="navbar navbar-expand-lg navbar-light fixed-top"
        style="background-color: transparent; box-shadow: none;">
        <div class="container">
            <a class="navbar-brand" href="#">
                <img src="assets/img/itechpay_picture.png" width="113px" height="34px" alt="">
            </a>
        </div>
    </nav>
    <div class="container align-items-center vertical-center">
        <div class="row">
            <div class="col-lg-10 col-xl-9 mx-auto">
                <div class="card card-signin flex-row my-5">
                    <div class="card-img-left d-none d-md-flex">
                        <div class="container elmt-center">
                            <div class="row">
                                <div class="col-12">
                                    <div class="wrap">
                                        <div class="ico-wrap">
                                            <span class="mbr-iconfont fa-shield fa"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h5 class="mbr-fonts-style mbr-bold mbr-section-title3 display-6">Plateforme
                                                sécurisée</h5>
                                            <small class="mbr-fonts-style text1 mbr-text">Effectuez vos
                                                transactions en toute sécurité</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="wrap">
                                        <div class="ico-wrap">
                                            <span class="mbr-iconfont fa-money fa"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h5 class="mbr-fonts-style mbr-bold mbr-section-title3 display-6">Moyens de
                                                paiement divers</h5>
                                            <small class="mbr-fonts-style text1 mbr-text">Nous intégrons plusieurs
                                                moyens de paiement</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="wrap">
                                        <div class="ico-wrap">
                                            <span class="mbr-iconfont fa-code fa"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h5 class="mbr-fonts-style mbr-bold mbr-section-title3 display-6">
                                                Intégration rapide</h5>
                                            <small class="mbr-fonts-style text1 mbr-text">Nos APIs sont simples et
                                                faciles à utiliser</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body align-items-center">
                        <ng-container *ngIf="loading">
                            <h5 class="text-center">Connexion en cours...</h5>
                            <div class="loading">
                                <div class="lds-dual-ring"></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!loading">
                            <h5 class="card-title text-center">Connexion</h5>
                            <hr class="underline" />
                            <form class="login-form">
                                <div class="form-label-group">
                                    <input type="text" id="username" id="username" name="username" autocomplete="current-password"
                                        [(ngModel)]="username" class="form-control" required autofocus>
                                    <label for="username">Téléphone</label>
                                </div>
                                <div class="form-label-group">
                                    <input type="password" id="password" name="password" autocomplete="username"
                                        [(ngModel)]="password" class="form-control" required>
                                    <label for="inputPassword">Mot de passe</label>
                                </div>

                                <div class="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" class="custom-control-input" id="customCheck1">
                                    <label class="custom-control-label" for="customCheck1">Se souvenir de moi</label>
                                </div>
                                <button (click)="login()"
                                    class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                                    type="submit">Se connecter</button>
                                <div class="">
                                    <a class="small color-primary" href="JavaScript:void(0);"
                                        (click)="ressetPassword()">J'ai oublié mon mot de passe</a>
                                    <a class="small float-right color-primary" routerLink="/inscription">Inscription</a>
                                </div>
                                <div class="">
                                    <a class="small color-primary" href="JavaScript:void(0);"
                                        (click)="accountActivation()">Activer mon compte</a>
                                </div>
                            </form>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>