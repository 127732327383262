import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from './auth-service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(protected router: Router, protected auth: AuthService, protected toastr: ToastrService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    if (this.auth.isLoggedIn()) {
      return true;
    } else {
      this.toastr.error("Vous n'êtes pas autorisé à accéder à cette page, veuillez d'abord vous connecter", 'Accès interdit');
      // return this.router.parseUrl('/login');
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }

}
