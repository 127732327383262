import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccueilComponent } from './accueil/accueil.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardService } from './auth/auth-guard';
import { RessetPasswordComponent } from './login/reset-password.component';
import { RessetPasswordConfirmComponent } from './login/reset-password-confirm.component';
import { AccountActivationComponent } from './login/account-activation.component';


const routes: Routes = [
  { path: '', redirectTo: '/accueil', pathMatch: 'full' },
  { path: 'paiements', loadChildren: () => import('./paiement/paiement.module').then(m => m.PaiementModule) },
  { path: 'deboursements', loadChildren: () => import('./deboursement/deboursement.module').then(m => m.DeboursementModule) },
  { path: 'paiements-client', loadChildren: () => import('./payment-link/payment-link.module').then(m => m.PaymentLinkModule) },
  { path: 'dashboard', canActivate: [AuthGuardService], component: DashboardComponent },

  { path: 'accueil', component: AccueilComponent },
  { path: 'login', component: LoginComponent },
  { path: 'resset-password', component: RessetPasswordComponent },
  { path: 'resset-password-confirm', component: RessetPasswordConfirmComponent },
  { path: 'account-activation', component: AccountActivationComponent },
  { path: 'inscription', component: RegisterComponent },
  //  { path: 'dashboard', canActivate: [AuthGuardService], component: DashboardComponent },
  //  { path: 'paiements', canActivate: [AuthGuardService], loadChildren: () => import('./paiement/paiement.module').then(m => m.PaiementModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
