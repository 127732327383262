<app-navigation [opac]="true"></app-navigation>
<header>
    <div class="header-item">
        <div class="container">
            <div class="row title-block">
                <div class="col-xs-12 col-md-6">
                    <h4 class="title"><b>Reçu de deboursement</b></h4>
                </div>
                <div class="col-md-6 col-xs-12">
                    <a class="btn btn-info btn-lg float-right" [routerLink]="['/dashboard']"
                        style="padding: 10px 22px; color: black;">Tableau de bord</a>
                </div>
            </div>
        </div>
    </div>
</header>
<section class="bg-grey dashboard">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-xs-12">
            <div>
                <!--<td style="width:50%; text-align: left;">
                                    <img height="100px" style="" src="re2.png" alt="logo bagri">
                                </td>-->
                <div style="width:50%; text-align: left;">
                    <img height="100px" style="position: absolute; z-index: 1;" src="assets/img/logo.jpg" alt="logo mde">
                </div>
            </div>
        
            <div id="centrer" style="width: 60%; margin-left: 40%; position: absolute;">
                <h1>
                    <b style="color:red;">Reçu de Deboursement N°
                        {{ donnees.reference }}</b>
                </h1>
        
            </div>
        
            <div>
        
                <div style="width:100%; text-align: right;">
                    <img height="100px" src="assets/img/logo.jpg" alt="logo mde">
                </div>
            </div>
        
            <div>
                <br/><br/><br/>
                <table>
        
                    <tr>
                        <td style="text-align: left;">
                            <b>PAYÉ PAR :</b><br/>
                            {{ donnees.nom }}<br/><br/>
        
                        </td>
                        <td style="text-align: right;">
                            <b>PAYÉ A :</b><br/>
                            Maison De L'Entreprise<br/><br/>
                        </td>
                    </tr>
                </table>
                <br/><br/><br/>
                <table>
                    <tr>
                        <td style="text-align: left;">
                            <b>DESCRIPTION :</b><br/>
                            Frais de création d'entreprise</td>
                        <td style="text-align: right;">
                            <b>MONTANT :</b><br/>
                            {{ donnees.montant }} F CFA</td>
                    </tr>
                </table>
                <br/><br/><br/>
                <table>
                    <tr>
                        <td style="text-align: left;">
                            <b>MONTANT TOTAL PAYÉ :</b><br/>
                            <b style="color:red;">{{ donnees.montant }} F CFA</b>
                        </td>
                        <td style="text-align: right;">
                            <b>DATE DE DEBOURSEMENT : </b><br/>
                            {{ donnees.dateDeboursement.date }} 
                        </td>
                    </tr>
                </table>
                <br/><br/>

            </div>
        </div>
    </div>
    </div>
</section>
<app-footer></app-footer>