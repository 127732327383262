import { Component, OnInit } from '@angular/core';
import { APP_URL } from '../shared/app.constants';
import { ToastrService } from 'ngx-toastr';
import { OperatorService } from '../operator/operator.service';
import { IOperator } from '../operator/operator.model';
import { TransactionService } from '../transaction/transaction.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-moyens-deboursement',
  templateUrl: './list-moyens-deboursement.component.html'
})
export class ListMoyensDeboursementComponent implements OnInit {

  urlRedirection: string;
  urlAirtelMoney: string;
  lds: boolean;
  operators: IOperator[];

  constructor(protected router: Router, protected toastr: ToastrService, protected operatorService: OperatorService, protected transactionService: TransactionService) { }

  ngOnInit(): void {
    this.urlRedirection = APP_URL + '/#/deboursements/confirmer';
    this.urlAirtelMoney = '/#/deboursements/confirmer';
    this.loadPaymentMethods();
  }

  itechpay() {
    this.toastr.error("L'accès à ce moyen de deboursement n'est pas encore disponible.", 'Service Indisponible');
  }

  loadPaymentMethods(): void {
    this.lds = true;
    this.operatorService.query().subscribe(res => {
      this.operators = res.body.data;
      this.lds = false;
    }, err => {
      this.toastr.error("Impossible de charger les moyens de deboursements, veuillez réessayer", 'Operator');
      console.error(err);
      this.lds = false;
    });
  }

  getOperatorLogo(operator: IOperator) {
    return "assets/img/" + operator.label + "_LOGO.png"
  }

  goToPaymentForm(operator: IOperator): void {
    this.transactionService.setOperator(operator);
    this.router.navigate(['/deboursements/confirmer']);
  }

}
