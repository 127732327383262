import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth-service';
declare var $: any;

@Component({
    selector: 'app-accueil',
    templateUrl: './accueil.component.html'
})
export class AccueilComponent implements OnInit {

    montantMaxCapital: number;
    isAutenticated: boolean;

    constructor(private auth: AuthService) { }
    ngOnInit(): void {
        if (this.auth.getCurrentUserLogin()) {
            this.isAutenticated = true;
        }

        $(document).ready(function () {
            // Handler for .ready() called.
            var scroll = $(document).scrollTop();
            if (scroll > 100) {
                $('.navbar.fixed-top').addClass('scrolling');
            } else {
                $('.navbar.fixed-top').removeClass('scrolling');
            }

            if (scroll > ($(window).height() - 100) && scroll < ($(document).height() - ($(window).height() + $('#footer').height()))) {
                $('.side-pan').addClass('fixed');
            } else if (scroll > ($(document).height() - ($(window).height() + $('#footer').height()))) {
                $('.side-pan').removeClass('fixed');
            } else {
                $('.side-pan').removeClass('fixed');
            }

            $(window).scroll(function () {
                scroll = $(window).scrollTop();
                if (scroll > 100) {
                    $('.navbar.fixed-top').addClass('scrolling');
                } else {
                    $('.navbar.fixed-top').removeClass('scrolling');
                }
                if (scroll > ($(window).height() - 100) && scroll < ($(document).height() - ($(window).height() + $('#footer').height()))) {
                    $('.side-pan').addClass('fixed');
                } else if (scroll > ($(document).height() - ($(window).height() + $('#footer').height()))) {
                    $('.side-pan').removeClass('fixed');
                } else {
                    $('.side-pan').removeClass('fixed');
                }
            });

            $("#howTo").click(function () {
                $('html, body').animate({
                    scrollTop: $("#howtosection").offset().top - 50
                }, 1000);
            });
        });
    }



}
