<app-navigation [opac]="true"></app-navigation>
<header>
    <div class="header-item">
        <div class="container">
            <div class="row title-block">
                <div class="col-xs-12 col-md-6">
                    <h4 class="title"><b>Historique des paiements</b></h4>
                </div>
                <div class="col-xs-12 col-md-6 head-action">
                    <button routerLink="/paiements/liste-moyens" class="btn btn-primary float-right"><i
                            class="fa fa-plus"></i> Nouveau
                        paiement</button>
                </div>
            </div>
        </div>
    </div>
</header>
<section class="bg-grey dashboard">
    <ng-container *ngIf="loading">
        <div class="loading">
            <div class="lds-dual-ring"></div>
        </div>
    </ng-container>
    <div class="container" *ngIf="!loading">
        <div class="row justify-content-center">
            <div class="col-12" *ngFor="let transaction of transactions">
                <div class="row upload-zone mb-2">
                    <div class="col-xs-12 col-md-3">
                        <img [src]="getOperatorLogo(transaction)" alt="Partenaire logo" height="32px" width="auto"
                            class="upload-caption">
                        <p class="paiement-title" style="display:inline-block; margin-left: 8px; width: 80%;">
                            {{transaction?.transactionId}}
                        </p>
                    </div>
                    <div class="col-xs-12 col-md-2">
                        <p class="paiement-title">
                            {{transaction?.numero}}
                        </p>
                    </div>
                    <div class="col-xs-12 col-md-2">
                        <p class="paiement-title">
                            {{transaction?.montant}} FCFA
                        </p>
                    </div>
                    <div class="col-xs-12 col-md-2">
                        <p class="paiement-title">
                            {{transaction?.createdAt.date | date:'d/MM/y à HH:mm'}}
                        </p>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="pull-right upload-zone-btn" style="display:inline-block; right: 0;">
                            <a *ngIf="transaction.statut === 'PENDING'" class="btn btn-info btn-sm upload-btn"
                                style="color: #fff;" (click)="check(transaction.transactionId)">Vérifier</a>
                            <span *ngIf="transaction.statut === 'FAILED'" class="badge badge-danger">Transaction
                                échouée</span>
                            <a *ngIf="transaction.statut === 'CONFIRMED'" class="btn btn-success btn-sm upload-btn"
                            style="color: #fff;" (click)="refund(transaction.transactionId)">Annuler</a>
                            <span *ngIf="transaction.statut === 'CANCELED'" class="badge badge-danger">Transaction
                                annulée</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>