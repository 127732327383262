<section class="full-height">
    <div class="container align-items-center">
        <div class="row">
            <div class="col-lg-10 col-xl-9 mx-auto">
                <div class="card card-signin flex-row my-5">
                    <div class="card-img-left d-none d-md-flex">
                        <div class="container align-items-center">
                            <div class="row">
                                <div class="col-12">
                                    <div class="wrap">
                                        <div class="ico-wrap" style="margin-right: 14px;">
                                            <span class="mbr-iconfont fa-shield fa"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h5 class="mbr-fonts-style mbr-bold mbr-section-title3 display-6">Plateforme
                                                sécurisée</h5>
                                            <small class="mbr-fonts-style text1 mbr-text">Effectuez vos
                                                transactions en toute sécurité</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="wrap">
                                        <div class="ico-wrap">
                                            <span class="mbr-iconfont fa-money fa"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h5 class="mbr-fonts-style mbr-bold mbr-section-title3 display-6">Moyens de
                                                paiement divers</h5>
                                            <small class="mbr-fonts-style text1 mbr-text">Nous intégrons plusieurs
                                                moyens de paiement</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="wrap">
                                        <div class="ico-wrap">
                                            <span class="mbr-iconfont fa-code fa"></span>
                                        </div>
                                        <div class="text-wrap vcenter">
                                            <h5 class="mbr-fonts-style mbr-bold mbr-section-title3 display-6">
                                                Intégration rapide</h5>
                                            <small class="mbr-fonts-style text1 mbr-text">Nos APIs sont simples et
                                                faciles à utiliser</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body align-items-center">
                        <ng-container *ngIf="loading">
                            <h5 class="text-center">Connexion en cours...</h5>
                            <div class="loading">
                                <div class="lds-dual-ring"></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!loading">
                            <h3 class="login-heading mb-2 text-center" style="font-weight: bold;">Activer mon compte
                            </h3>
                            <hr class="underline" />
                            <form class="activation-form">
                                <div class="form-label-group">
                                    <input type="text" id="username" id="username" name="username"
                                        [(ngModel)]="username" class="form-control" required autofocus>
                                    <label for="username">N° de téléphone</label>
                                </div>
                                <div *ngIf="show" class="form-label-group">
                                    <input type="text" id="code" id="code" name="code" [(ngModel)]="code"
                                        class="form-control" required>
                                    <label for="code">Code</label>
                                </div>
                                <div class="" *ngIf="!loading && show">
                                    <p class="small"><a href="JavaScript:void(0);" class="color-primary"
                                            (click)="ressendCode()">Je n'ai pas reçu le code</a></p>
                                </div>
                                <button *ngIf="!show" (click)="resset()"
                                    class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                                    type="submit">Recevoir le code de confirmation</button>
                                <button *ngIf="show" (click)="confirm()"
                                    class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                                    type="submit">Confirmer</button>
                            </form>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>