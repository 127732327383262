import { StorageService, SESSION_STORAGE } from 'ngx-webstorage-service';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SERVER_API_URL, TOKEN_TTL } from '../shared/app.constants';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ItechpayUser } from './user.model';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})

export class AuthService {

  public readonly JWT_TOKEN = 'JWT_TOKEN';
  public readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  public readonly TOKEN_EXPIRE_AT = 'TOKEN_EXPIRE_AT';
  public readonly LOGGED_IN_USER = 'LOGGED_IN_USER';
  private autenticated: boolean;
  private ressetUser: string;
  public resourceUrl = SERVER_API_URL + 'api/users';
  public currentUser?: ItechpayUser;

  constructor(private http: HttpClient, @Inject(SESSION_STORAGE) private localStorage: StorageService, protected router: Router, protected toastr: ToastrService) { }

  login(cred): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/login`, cred, { observe: 'response' }).pipe(tap(res => {
      if (res.body.data.status == 'success') this.setSession(cred.username);
    }));
  }

  jwtLogin(cred): Observable<any> {
    return this.http.post<any>(SERVER_API_URL + 'api/login_check', cred, { observe: 'response' })
      .pipe(tap(res => {
        this.setJwtSession(res.body, cred.username);
      }));
  }

  refreshToken(): Observable<any> {
    return this.http.post<any>(SERVER_API_URL + 'api/token/refresh', { refresh_token: localStorage.getItem('refresh_token') })
      .pipe(tap(res => {
        this.storeJwtToken(res.body.token);
      }));
  }

  setJwtSession(authResult, username) {
    this.autenticated = true;
    this.localStorage.set(this.LOGGED_IN_USER, username);
    const expiresAt = moment().add(TOKEN_TTL, 'second');
    this.localStorage.set(this.JWT_TOKEN, authResult.token);
    this.localStorage.set(this.TOKEN_EXPIRE_AT, JSON.stringify(expiresAt.valueOf()));
    this.localStorage.set(this.REFRESH_TOKEN, JSON.stringify(authResult.refresh_token));
  }

  register(value: FormData) {
    return this.http.post<any>(`${this.resourceUrl}/register`, value, { observe: 'response' });
  }

  setSession(username) {
    this.autenticated = true;
    this.localStorage.set(this.LOGGED_IN_USER, username);
  }

  logout() {
    this.autenticated = false;
    this.localStorage.clear();
  }

  public isLoggedIn() {
    if (!this.localStorage.get(this.LOGGED_IN_USER) || moment().isAfter(this.getExpiration())) return false;
    return true;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  isAuthenticated() {
    return this.autenticated;
  }

  getExpiration() {
    const expiration = this.localStorage.get(this.TOKEN_EXPIRE_AT);
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  private storeJwtToken(jwt: string) {
    this.localStorage.set(this.JWT_TOKEN, jwt);
  }

  getCurrentUserLogin() {
    return this.localStorage.get(this.LOGGED_IN_USER);
  }

  getCurrentUser() {
    return this.http.get<any>(`${this.resourceUrl}/${this.localStorage.get(this.LOGGED_IN_USER)}/username`, { observe: 'response' });
  }

  getConfirmCode(phone: string) {
    return this.http.post<any>(`${this.resourceUrl}/${phone}/checks`, { observe: 'response' });
  }

  registerItechpayMarchand(data: FormData) {
    return this.http.post<any>(`${SERVER_API_URL}api/register/marchand`, data, { observe: 'response' });
  }

  activateAccount(data: FormData) {
    return this.http.post<any>(`${SERVER_API_URL}api/register/activate_account`, data, { observe: 'response' });
  }

  registerItechpayMarchandConfirm(data: FormData) {
    return this.http.post<any>(`${SERVER_API_URL}api/register/marchand/confirm`, data, { observe: 'response' });
  }

  resendCode(data: FormData) {
    return this.http.post<any>(`${SERVER_API_URL}api/register/ressend_code`, data, { observe: 'response' });
  }

  ressetPassword(data: FormData) {
    return this.http.post<any>(`${this.resourceUrl}/resset_password`, data, { observe: 'response' });
  }

  ressetPasswordConfirm(data: FormData) {
    return this.http.post<any>(`${this.resourceUrl}/resset_password/confirm`, data, { observe: 'response' });
  }

  setRessetUser(user: string): void {
    this.ressetUser = user;
  }

  getRessetUser(): string {
    return this.ressetUser;
  }

}
