import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth-service';
import { ToastrService } from 'ngx-toastr';
import { StorageService, SESSION_STORAGE } from 'ngx-webstorage-service';
import { Injectable, Inject } from '@angular/core';
import { TransactionService } from '../transaction/transaction.service';
import { ITransaction } from '../transaction/transaction.model';


@Component({
  selector: 'app-dashboard',
  templateUrl: './list.component.html'
})
export class ListComponent implements OnInit {
  loading: boolean;
  transactions: ITransaction[];
  currentUser: string;
  token: string;
  public readonly LOGGED_IN_USER = 'LOGGED_IN_USER';


  constructor(protected router: Router, protected toastr: ToastrService, @Inject(SESSION_STORAGE) private localStorage: StorageService, private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.currentUser = this.localStorage.get(this.LOGGED_IN_USER);
    this.loadTransactions();
  }

  loadTransactions() {
    this.loading = true;
    this.transactionService.disbursementByMarchand(this.currentUser).subscribe(res => {
      this.transactions = res.body.data;
      this.loading = false;
    }, err => {
      console.error(err);
      this.loading = false;
    });
  }

  check(transId: string) {
    this.loading = true;
    this.transactionService.check(transId).subscribe(res => {
      if (res.data.statut !== 'CONFIRMED') {
        this.toastr.error("Transaction non confirmée", 'Transaction');
      } else {
        this.toastr.success("Transaction confirmée", 'Transaction');
      }
      this.loadTransactions();
      this.loading = false;
    }, err => {
      console.error(err);
      this.toastr.error("Erreur lors de la vérification du paiement", 'Transaction');
      this.loading = false;
    });
  }

  refund(transId: string) {
    this.loading = true;
    this.transactionService.refund(transId).subscribe(res => {
      if (res.status != 200) {
        this.toastr.error(res.message, 'Transaction');
      } else {
        this.toastr.success("Transaction annulée", 'Transaction');
      }
      this.loadTransactions();
      this.loading = false;
    }, err => {
      console.error(err);
      this.toastr.error("Erreur lors de l'annulation du paiement", 'Transaction');
      this.loading = false;
    });
  }

  getOperatorLogo(transaction: ITransaction) {
    return "assets/img/" + transaction.operatorLabel + "_LOGO.png"
  }

}
