import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  returnUrl: string;
  loading: boolean;

  constructor(private auth: AuthService,
    private route: ActivatedRoute,
    protected router: Router, protected toastr: ToastrService) { }

  ngOnInit(): void {
    // reset login status
    this.auth.logout();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/accueil';
  }

  login() {
    this.loading = true;
    this.auth.jwtLogin({
      username: this.username,
      password: this.password
    }).subscribe(res => {
      this.loading = false;
      this.router.navigate([this.returnUrl]);
      // if (res.body.data.status == 'success') {
      // } else {
      //   this.toastr.error(res.body.data.message, 'Connexion');
      // }
    }, err => {
      this.loading = false;
      console.error(err);
      this.toastr.error("Erreur lors de la connexion, identifiant ou mot de passe incorrects. Ou vérifiez que vous êtes bien connectés à Internet.", 'Connexion');
    });
  }

  ressetPassword() {
    this.router.navigate(['resset-password']);
  }

  accountActivation() {
    this.router.navigate(['account-activation']);
  }

}
