<footer id="footer" class="section footer-classic context-dark bg-image" style="background: #7c2311">
  <div class="container">
    <div class="row row-30">
      <div class="col-md-4 col-lg-5">
        <div class="pr-xl-4"><a class="brand" href="#"><img class="brand-logo-light"
              src="assets/img/itechpay_picture.png" alt="" width="140" height="auto"
              srcset="images/agency/logo-retina-inverse-280x74.png 2x"></a>
          <p>Agrégateur de Moyens de Paiements Électroniques<br><small>
              <br>
              APIPAY est une plateforme d'agrégation de moyens de paiements électroniques. Vous créez un seul compte sur APIPAY et vous recevez des paiements de vos clients à travers tous les moyens de paiements électroniques disponibles sur la plateforme.
            </small></p>
        </div>
        <div class="">
          <a href="https://www.facebook.com/itechcenter.ne"><i id="social-fb" class="fa fa-facebook-square fa-3x social"></i></a>
          <a href="https://twitter.com/itechcenter_ne"><i id="social-tw" class="fa fa-twitter-square fa-3x social"></i></a>
        </div>
      </div>
      <div class="col-md-4">
        <h5 class="color-white"><b>Contacts</b></h5>
        <dl class="contact-list">
          <dt>Adresse:</dt>
          <dd>Quartier ZAC, derrière le Centre Aéré BCEAO, BP 2712 Niamey, Niger</dd>
        </dl>
        <dl class="contact-list">
          <dt>Email:</dt>
          <dd><a href="mailto:#">itechpay@itechcenter.ne</a></dd>
        </dl>
        <dl class="contact-list">
          <dt>Téléphone:</dt>
          <dd><a href="tel:#">+227 93 76 09 60</a>
          </dd>
        </dl>
      </div>
      <div class="col-md-4 col-lg-3">
        <h5 class="color-white"><b>Liens utiles</b></h5>
        <ul class="nav-list">
          <li><a href="#">ITECHPAY</a></li>
          <li><a href="#">ITECH CENTER</a></li>
          <li><a href="#">ITECH BUSINESS</a></li>
        </ul>
      </div>
    </div>
    <hr class="footer-line" />
    <div class="row text-center">
      <div class="col-12">
        <p class="rights"><span>Un produit de ITECHCENTER</span><br/><span class="copyright-year">2020
          </span><span><span>©</span> </span><span>Tout droit réservé.</span></p>
      </div>
    </div>
  </div>
</footer>