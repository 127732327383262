import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListMoyensDeboursementComponent } from './list-moyens-deboursement.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { DeboursementComponent } from './deboursement.component';
import { RecuComponent } from './recu.component';
import { ListComponent } from './list.component';



@NgModule({
  declarations: [ListMoyensDeboursementComponent, DeboursementComponent,
    RecuComponent, ListComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: 'liste',
        component: ListComponent
      },
      {
        path: 'liste-moyens',
        component: ListMoyensDeboursementComponent
      },
      {
        path: 'confirmer',
        component: DeboursementComponent
      },
      {
        path: 'recu',
        component: RecuComponent
      }
    ])
  ]
})
export class DeboursementModule { }
